import { Pipe, PipeTransform } from '@angular/core';
@Pipe({  name: 'values' })
export class ValuesPipe implements PipeTransform {

    transform(records: Array<any>): any {
        let values = [];
        for (let value in records) {
            values.push(records[value]);
        }
        return values;
    }
}
