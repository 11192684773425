import { Component, OnInit } from '@angular/core';

import { AgFilterComponent } from 'ag-grid-angular';
import {
  AppliedFiltersData,
} from '../table-column-filters/table-column-filters-models';
import { DatePipe } from '@angular/common';
import { DriverDataDetailConstants } from 'src/app/shared/constants';
import { FilterOpenService } from '../table-column-filters/table-column-filters.service';
import { IDoesFilterPassParams } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { revo_core_labels } from 'src/app/shared/constants/ui_labels_translation_mapping';

@Component({
  selector: 'app-zs-date-range',
  templateUrl: './zs-date-range.component.html',
  styleUrls: ['./zs-date-range.component.less'],
})
export class ZsDateRangeComponent implements OnInit, AgFilterComponent {
  enableMultiSelect = true;
  minOptionsForSearch = 0;
  dataSource: any;
  tableName: string;
  PreSelectedOptionValues: Array<AppliedFiltersData>;
  isFilter = false;
  filterClass = '';
  filterId: string;
  filterName: string;
  startDate: any;
  displayLabelKey = 'key';
  showClearAllOption = false;
  selectedOptionValues = [];
  searchText = '';
  showDropdown = false;
  filterActive = false;
  loadData = { value: false };
  uiLabels = revo_core_labels.MULTI_SELECT_LABELS;
  headerDisplayNameShowLimit =
    DriverDataDetailConstants.headerDisplayNameShowLimit;
  endDate: any;
  translated_labels: any;
  processListLabels = {
    FILTER_DATE_RANGE: "Filter by date range",
    START: "Start",
    END: "End",
    APPLY: "Apply",
    RESET: "Reset",
  };

  filterRangeLabel: string;
  startdateLabel: string;
  enddateLabel: string;
  applyLabel: string;
  resetLabel: string;
  minDate: Date;
  columnName: any;
  isSelected = false;
  isISO: boolean;

  constructor(
    private _filterOpenService: FilterOpenService,
    public datepipe: DatePipe,
    private _translate: TranslateService
  ) {
    this.minDate = new Date(1990, 1, 1);
    this.translated_labels = this._translate.instant([
      this.processListLabels.FILTER_DATE_RANGE,
      this.processListLabels.START,
      this.processListLabels.END,
      this.processListLabels.APPLY,
      this.processListLabels.RESET,
    ]);
    this.filterRangeLabel = this.translated_labels[
      this.processListLabels.FILTER_DATE_RANGE
    ];
    this.startdateLabel = this.translated_labels[
      this.processListLabels.START
    ];
    this.enddateLabel = this.translated_labels[this.processListLabels.END];
    this.applyLabel = this.translated_labels[this.processListLabels.APPLY];
    this.resetLabel = this.translated_labels[this.processListLabels.RESET];
  }

  ngOnInit() {
    this.startDate = this.datepipe.transform(new Date(), 'MM/dd/yyyy');
    this.endDate = this.datepipe.transform(new Date(), 'MM/dd/yyyy');
  }

  onApply() {
    this._filterOpenService.setLoadFilterData({
      'column' : this.columnName,
      'start_date': this.isISO ? this.convertDateToISO(this.startDate) : this.startDate,
      'end_date': this.isISO ? this.convertDateToISO(this.endDate) : this.endDate
    });
    this.isSelected = true;
  }

  convertDateToISO(date) {
    if (new Date(date).toString() !== 'Invalid Date' && date !== null) {
      date = new Date(date).toISOString();
    }
    return date;
  }

  onReset() {
    this.startDate = this.endDate = new Date().toString();
    this._filterOpenService.setLoadFilterData({
      'column' : this.columnName,
      'start_date': null,
      'end_date': null
    });
    this.isSelected = false;
  }

  startDateChange($event) {
    this.startDate = $event;
  }

  endDateChange($event) {
    this.endDate = $event;
  }

  // AG FILTER INTERFACE
  agInit(params: any) {
    this.showDropdown = true;
    this.columnName = params['Column'];
    this.isISO = params['isISO'];
  }

  isFilterActive() {
    if (this.isSelected) {
      this.filterActive = true;
    } else {
      this.filterActive = false;
    }
    return this.filterActive;
  }

  doesFilterPass(params: IDoesFilterPassParams) {
    return true;
  }

  getModel() {
    // pass
  }

  setModel(model: any) {
    // pass
  }
}
