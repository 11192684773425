<div class="auto-suggest-div">
  <p class="zs-action-field" [ngStyle]="selectCustomStyle">
    <label class="auto-suggest-label" *ngIf="label">{{label}}</label>
    <span class="zs-input-icon zs-icon-carat-down zs-interactive-secondary auto-suggest-span" [ngStyle]="selectCustomStyle">
      <input type="text" class="auto-suggest-field" [(ngModel)]="selected"
        (input)="onTextChange($event.target.value)" [placeholder]="emptyValue" (blur)="blurEvent()"
        [ngStyle]="selectCustomStyle" [ngClass]="touched ? inputClass : ''">
      <ul #menu *ngIf="displayMenu && dataSource" class="zs-menu auto-suggest-menu" (changed)="menuOpened()">
        <ng-container *ngIf="{value: (dataSource 
          | filterTable : ['displayLabel'] : this.searchText : false 
          )} as filterData;">
          <ng-container *ngFor="let menu of filterData?.value">
            <li (mousedown)="selectValueFromMenu(menu)">
              <span [innerHtml]="this.getDisplayLabel(menu['displayLabel'])"></span>
            </li>
          </ng-container>
        </ng-container>
      </ul>
    </span>
  </p>
  <span *ngIf="isInErrorState" [ngStyle]="selectCustomStyle" class="zs-message zs-error auto-suggest-error">{{errorMessage}}</span>
</div>