import { Component, Input, OnInit, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { OozieStatus } from 'src/app/shared/constants';
import ProgressBar from '../../../../node_modules/zsui/src/progressBar/progressBar.m.js';
ProgressBar.register();

@Component({
  selector: 'app-zs-progress-bar',
  templateUrl: './zs-progress-bar.component.html',
  styleUrls: ['./zs-progress-bar.component.less']
})
export class ZsProgressBarComponent implements OnChanges {

  @Input() value;
  @Input() label = false;
  @Input() animate = true;
  @Input() status;
  @Input() max = 100;
  @Input() duration = 300;
  @Input() metrics = '%';
  @Input() norender = false;

  modeClass = '';
  StatusValues = OozieStatus;

  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    this.changeStatusType();
  }

  changeStatusType() {
      this.modeClass = '';
      if (this.status === this.StatusValues.RUNNING) {
          this.modeClass = '';
      } else if (this.status === this.StatusValues.FAILED || this.status === this.StatusValues.KILLED
        || this.status === this.StatusValues.SUSPENDED) {
          this.modeClass = 'zs-danger';
      } else if (this.status === this.StatusValues.SUCCEEDED) {
          this.modeClass = 'zs-success';
      } else if (this.status === this.StatusValues.SUBMITTED || this.status === this.StatusValues.INQUEUE) {
        this.modeClass = 'zs-warning';
      } else if (this.value === 0) {
        this.modeClass = 'zero-progress';
      }
  }

}
