<div class="parent-menu" (mouseleave)="showMenu = false" (mouseover)="showMenu = true" [ngStyle]="customStyle">
	<a [class]="menuLabelClass">{{ menuLabel }}</a>

	<div *ngIf="showMenu" class="zs-menu">
		<ul>
			<li *ngFor="let menu of menuList">
				<a (click)="handleMenuClick(menu.value)" class="zs-link-action">
					<span [ngClass]="menu.class"></span>
					<span>{{ menu.value }}</span>
				</a>
			</li>
		</ul>
	</div>
</div>