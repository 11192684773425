import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import Toggle from '../../../../node_modules/zsui/src/toggle/toggle.m.js';
import { revo_core_labels } from 'src/app/shared/constants/ui_labels_translation_mapping';

Toggle.register('zs-toggle', 'p');

@Component({
  selector: 'app-zs-toggle',
  templateUrl: './zs-toggle.component.html',
  styleUrls: ['./zs-toggle.component.less']
})
export class ZsToggleComponent implements OnInit {
  @Input() isActive: boolean;
  @Input() elementId: string;
  @Input() isDisabled: boolean;
  @Output() toggleClicked = new EventEmitter(true);
  toggleId = 'toggle-component-';
  ui_labels = revo_core_labels.TOGGLE;
  constructor() {
  }

  toggleValue(value=null) {
    if ([true, false].includes(value)) {
      this.isActive = value;
    } else {
      this.isActive = !this.isActive;
    }
  }

  ngOnInit() {
  }

  onclick() {
    this.isActive = !this.isActive;
    this.toggleClicked.emit(this.isActive);
  }
}
