import { Component, OnInit, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-zs-menu',
  templateUrl: './zs-menu.component.html',
  styleUrls: ['./zs-menu.component.less']
})
export class ZsMenuComponent implements OnInit {

  constructor() { }

  @Input() menuList: any[];
  @Input() onMenuClickCallBackFunction: Function;
  @Input() menuLabel: string;
  @Input() customStyle: any;
  @Input() menuLabelClass: string;

  showMenu = false;

  ngOnInit() {
  }

  handleMenuClick(selectMenuValue: string) {
    if (this.onMenuClickCallBackFunction) {
      this.onMenuClickCallBackFunction(selectMenuValue);
    }
  }

  toggleMenu() {
    this.showMenu = this.showMenu ? false : true;
  }

}
