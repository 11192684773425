import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SecurityContext,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { DomSanitizerConstants } from 'src/app/shared/constants/security-attacks-constants';
import { DomSanitizerPipe } from 'src/app/shared/custom-pipes/dom-sanitizer-pipe';
import { FormValidationService } from '../form-validation-service/form-validation-service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { revo_core_labels } from 'src/app/shared/constants/ui_labels_translation_mapping';

// import { brm_labels } from 'src/app/brm/constants/ui-translation';


@Component({
  selector: 'app-zs-auto-suggest',
  templateUrl: './zs-auto-suggest.component.html',
  styleUrls: ['./zs-auto-suggest.component.less'],
})
export class ZsAutoSuggestComponent implements OnInit, OnDestroy, OnChanges {
  @Input() dataSource: any;
  @Input() selected: string;
  @Input() isRequired: boolean;
  @Input() formValidationCallbackFunction: Function;
  @Input() isInErrorState: Boolean;
  @Input() emptyValue;
  @Input() label: string;
  @Input() elementId: string;
  @Input() touched = false;
  @Input() selectCustomStyle: any;
  @Input() validationFunction: Function = null;

  errorClass = 'zs-error';
  errorMessage = '';
  displayMenu = false;
  searchText = '';

  @Output() selectedValueChange: EventEmitter<String> =
    new EventEmitter<String>(true);
  @Output() isInErrorStateChange = new EventEmitter(true);
  @Output() selectedTextChange = new EventEmitter(true);

  @ViewChild('menu') menu: ElementRef;

  formValidationSubscription: Subscription;
  @HostBinding('class.zs-error') public inputClass = '';

  translated_labels: any;
  validation_labels = revo_core_labels.VALIDATION_LABELS;
  // ui_labels =
  //   brm_labels.BUSINESS_RULE_GROUP_DETAILS.BUSINESS_RULE_DETAILS.RULE_DEFINATION
  //     .RULE_CONDITION;

  constructor(
    private _formValidationService: FormValidationService,
    private _translate: TranslateService,
    private _xssSafePipe: DomSanitizerPipe
  ) {
    this.translated_labels = this._translate.instant([
      this.validation_labels.VALUE_REQUIRED
    ]);
    if (this.emptyValue === undefined) {
      this.emptyValue = "select"
        // this.translated_labels[this.ui_labels.SELECT_VALUE_LABEL];
    }
  }

  validate(isEmitEnabled: boolean) {
    const $this = this;
    if (this.validationFunction != null) {
      this.errorMessage = this.validationFunction(this.selected);
      if (this.errorMessage == null) {
        this.resetSelect();
      } else {
        this.inputClass = this.errorClass;
        this.isInErrorState = true;
      }
    } else if (this.isValueRequired()) {
      this.inputClass = this.errorClass;
      this.errorMessage =
        this.translated_labels[this.validation_labels.VALUE_REQUIRED];
      this.isInErrorState = true;
    } else {
      this.resetSelect();
    }
    if (isEmitEnabled) {
      $this.selectedValueChange.emit($this.selected);
      this.isInErrorStateChange.emit(this.isInErrorState);
    }
  }

  isValueRequired() {
    if (this.isRequired && (!this.selected || this.selected.trim() === '')) {
      return true;
    }
    return false;
  }

  resetSelect() {
    this.inputClass = '';
    this.errorMessage = '';
    this.isInErrorState = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    // As formValidationCallbackFunction dynamically changes in case of BRM lookup fields
    if (changes['formValidationCallbackFunction']) {
      if (
        changes['formValidationCallbackFunction'].currentValue === false &&
        this.formValidationSubscription
      ) {
        this.formValidationSubscription.unsubscribe();
      } else if (changes['formValidationCallbackFunction'].currentValue) {
        this.formValidationSubscription = this._formValidationService
          .getMessage()
          .subscribe((message) => {
            this.touched = true;
            this.validate(true);
            this.formValidationCallbackFunction(this.isInErrorState);
          });
      }
    }
  }

  ngOnInit() {
    this.blurEvent();
  }

  selectValueFromMenu(menu: any) {
    if (menu) {
      this.selected = menu['displayLabel'];
      this.selectedValueChange.emit(this.selected);
    }
    this.displayMenu = false;
    if (this.touched) {
      this.validate(true);
    }
    this.touched = true;
  }

  blurEvent() {
    this.selectedValueChange.emit(this.selected);
    this.displayMenu = false;
    if (this.touched) {
      this.validate(true);
    }
    this.touched = true;
  }

  onTextChange(text: any) {
    if (text) {
      this.displayMenu = true;
    } else {
      this.displayMenu = false;
    }
    this.searchText = text;
  }

  menuOpened() {
    this.menu.nativeElement.focus();
  }

  getDisplayLabel(text: any) {
    text = this._xssSafePipe.transform(
      text,
      SecurityContext.HTML,
      DomSanitizerConstants.SANITIZE
    );
    text = text.replace(/>/g, '> ');
    const reg = new RegExp('(?:|^)' + this.searchText, 'gi');
    return text.replace(reg, (match) => {
      return '<mark>' + match + '</mark>';
    });
  }

  ngOnDestroy() {
    if (this.formValidationCallbackFunction) {
      this.formValidationSubscription.unsubscribe();
    }
  }
}
