import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'isChecked',
    pure: true
})
export class IsCheckedPipe implements PipeTransform {
    transform(rowId: string, rowIdList: Array<string>): any {
        let isChecked = false;
        if (rowIdList && rowIdList.length > 0) {
            rowIdList.forEach(element => {
                if (element === rowId) {
                    isChecked = true;
                }
            });
        }
        return isChecked;
    }
}
